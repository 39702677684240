@import "../../../../styles/utils";
@import "../../../../styles/breakpoints";

.form {
  padding-bottom: pixelToRem(40);
}

.progress {
  width: pixelToRem(80);
}

.label {
  color: $black;
  font-size: pixelToRem(14);
  line-height: pixelToRem(19);
  font-weight: 600;
  display: block;
  margin-bottom: 0;
}
