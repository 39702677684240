@import 'colors';

$base-size: 16;

@function pixelToRem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

@mixin border-button($color: $rac-blue) {
  background-color: $white;
  border: 2px solid $color;
  color: $color;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 17px;
  outline: none;
}

@mixin fill-button($color: $white, $backgroundColor: $rac-medium-grey) {
  background-color: $backgroundColor;
  border: 0;
  color: $color;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 17px;
  outline: none;
}
