@import "../../../../styles/colors.scss";
@import "../../../../styles/utils.scss";

.selectLabel {
  font-size: pixelToRem(14);
  font-weight: 600;
  line-height: pixelToRem(19);
  color: $rac-black-medium;
  display: block;
  margin-bottom: 0;
}

.selectItemComponent {
  outline: none;
  color: $black;
  font-size: 0.875rem;
  line-height: pixelToRem(23);
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  height: pixelToRem(40);
}

.opacity02 {
  opacity: 0.2;
}
