$rac-blue: #0f99d6;
$rac-light-grey: #f6f9fb;
$rac-grey-disabled: #efefef;
$rac-dark-grey: #505050;
$rac-medium-grey: #b0b0b0;
$rac-red: #ff0000;
$rac-green: #8dc63f;
$rac-yellow: #fed405;
$rac-card-border: #f0f0f0;
$white: #fff;
$black: #000;
$rac-grey: #cbcbcb;
$rac-dialog-border: #ffcd00;
$rac-action-border: #dbdbdb;
$rac-blue-dark: #034694;
$rac-black-medium: rgba(0, 0, 0, 0.8);
