@import "../../../../styles/utils";
@import "../../../../styles/breakpoints";

.submitBtn {
  width: 100%;
}

.savePaymentMethod {
  margin-bottom: 0;
}
