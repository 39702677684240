@import "./styles/breakpoints.scss";
@import "./styles/colors.scss";
@import "./styles/utils.scss";
@import "./styles/variables.scss";

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-size: $font-size-base;
  height: 100%;
  font-family: $font-family-base;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
