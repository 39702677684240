@import "../../../../styles/utils";
@import "../../../../styles/colors";

.progress {
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-size: pixelToRem(18);
    padding-bottom: pixelToRem(10);
  }
}
