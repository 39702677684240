@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: pixelToRem(77);

  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    $white 100%
  );
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.footerInContainer {
  left: pixelToRem(50);
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  button {
    &:first-of-type {
      margin-right: pixelToRem(40);
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@include tabletUp {
  .footer {
    border-top: 1px solid $rac-dark-grey;
    background: none;
    box-shadow: none;
    background-color: $rac-card-border;
    padding: 0;
    height: pixelToRem(77);
    width: 100%;
    left: auto;
  }

  .btnWrapper {
    justify-content: flex-end;
    padding: 0 pixelToRem(40);
  }
}
